<template lang="pug">
.footer-bottom.is-flex
  .footer-bottom__accessiabiliti.sr-only
    span At {{ companyName }} we strive to make our website accessible to all, including those which utilize screen readers. Should you experience any issues during your visit, please reach out and let us know. We are always looking for ways to improve and would love the opportunity to better serve you. You can contact us via message, email or phone at the link that follows:&nbsp;
    v-link(to="contact-us") Contact Us Page
  .footer-bottom__logos.is-flex
    v-link(v-for="logo in logos", :key="logo.src", target="_blank", type="native", :to="logo.url")
      v-image(:src="logo.src", :width="logo.width", :height="logo.height", :alt="logo.alt")
  .footer-bottom__links.is-flex
    v-link(v-for="link in links", :key="link.text", :to="link.route", target='_blank', :type="type", v-html="link.text")
</template>

<script>
import { companyName } from '~~/utils/definitions/defaults'

export default {
  name: 'FooterBottomComponent',

  props: {
    type: {
      type: String,
      default: 'nuxt'
    }
  },

  computed: {
    domain() {
      return process.env.prodDomain
    },

    wpDomain() {
      return process.env.wpDomain
    },

    companyName() {
      return companyName[this.$site.name]
    },

    logos() {
      return [
        // {
        //   src: 'layout/logo-bbb@2x.jpg',
        //   alt: 'The Natural Sapphire Ruby & Emerald Company BBB Business Review',
        //   width: '78',
        //   height: '30',
        //   url: 'https://www.bbb.org/us/ny/new-york/profile/jewelry-stores/the-natural-sapphire-ruby-emerald-company-0121-103254/#sealclick'
        // },
        {
          src: 'layout/logo-verfied-reviews@2x.jpg',
          alt: 'Customer reviews for The Natural Sapphire Ruby & Emerald Company',
          width: '113',
          height: '21',
          url: 'https://www.verified-reviews.com/reviews/thenaturalsapphirecompany.com'
        }
      ]
    },

    links() {
      return [
        {
          text: `©&nbsp;${this.domain}`,
          route: this.localePath({ name: 'index' })
        },
        {
          text: this.$t('layout.footer.bottom.terms'),
          route: this.localePath({
            name: 'education-index-category',
            params: { category: 'frequently-asked-questions' },
            hash: '#privacy-policy'
          })
        },
        {
          text: this.$t('layout.footer.bottom.privacy'),
          route: this.localePath({
            name: 'education-index-category',
            params: { category: 'frequently-asked-questions' },
            hash: '#privacy-policy'
          })
        }
      ]
    }
  }
}
</script>

<style lang="sass" scoped>
.footer-bottom
  margin: 24px 0
  justify-content: space-between
  align-items: center
  +tablet
    margin-bottom: 150px
  +mobile
    align-items: flex-start
    flex-direction: column
  &__accessiabiliti
    text-align: center
    margin-bottom: 30px
    a
      color: $primary-color
  &__logos
    align-items: center
    width: 100%
    max-width: 426px
    +tablet-only
      max-width: 320px
    a + a
      margin-left: 20px
  &__links
    +mobile
      margin-top: 20px
      align-items: flex-start
      flex-direction: column
    a + a
      margin-left: 34px
      +tablet-only
        margin-left: 20px
      +mobile
        margin-top: 10px
        margin-left: unset
</style>
